<script setup>
import {computed, onMounted, onUnmounted, provide, reactive, ref} from "vue"

const props = defineProps({
  as: {
    type: String,
    default: "div",
  },
  active: {
    type: Number,
    default: 1,
  },
  duration: {
    type: Number,
    default: 2000,
  },
  featureDuration: {
    type: Number,
    default: 1000,
  },
})

const container = ref()
const activeItems = reactive([])
const children = ref([])

const items = computed(() => Array.from(children.value.keys()))
const distance = computed(() => Math.floor(items.value.length / (props.active + 1)))
const featuredItem = ref(null)

function getAvailableValues() {
  return items.value.filter((value) => {
    return !activeItems.some((activeItem) => {
      return Math.abs(activeItem - value) < distance.value
    })
  })
}

function getRandomValue() {
  const values = getAvailableValues()

  if (!values.length) {
    return false
  }

  const index = Math.floor(Math.random() * (values.length - 1))

  return values[index]
}

function addItem() {
  const value = getRandomValue()

  if (value !== false) {
    activeItems.push(value)
  }

  if (activeItems.length > props.active) {
    activeItems.shift()
  }
}

function setFeaturedItem() {
  const availablePlaces = items.value.filter((value) => {
    return Math.abs(featuredItem.value - value) > items.value.length / 3
  })

  const index = Math.floor(Math.random() * (availablePlaces.length - 1))

  featuredItem.value = availablePlaces[index]
}

let addItemInterval = null
let setFeaturedItemInterval = null

onMounted(() => {
  children.value = Array.from(container.value.children)

  setFeaturedItem()

  for (var i = 0; i < props.active; i++) {
    addItem()
  }

  addItemInterval = setInterval(() => addItem(), props.duration)
  setFeaturedItemInterval = setInterval(() => setFeaturedItem(), props.featureDuration)
})

onUnmounted(() => {
  clearInterval(addItemInterval)
  clearInterval(setFeaturedItemInterval)
})

provide("stars-context", {
  peers: children,
  activeItems,
  featuredItem,
})
</script>

<template>
  <component :is="as" ref="container">
    <slot/>
  </component>
</template>
