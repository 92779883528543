<template>
  <div
      class="mx-auto relative w-full max-w-full drop-shadow-xl bg-electric-violet-500 contrast-more:bg-electric-violet-600 text-white rounded-5xl p-6 sm:p-9 overflow-hidden border"
      @click="isInteracting()">

    <StarGrid :active="20" :duration="100" :feature-duration="1500"
              class="lg:grid w-full grid-cols-10 gap-4 absolute opacity-20 bottom-0 z-[-15] hidden">
      <StarGridItem
          v-for="(item, index) in items"
          :key="index"
          v-slot="{ isActive, isFeatured }"
          class="relative flex aspect-square w-full items-center justify-center">
        <svg
            :class="[isFeatured ? 'scale-1' : 'scale-0 opacity-0']"
            class="absolute h-6 w-6 stroke-electric-violet-200/50 stroke-[1] transition-all duration-1000"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="10.5"/>
        </svg>

        <div
            :class="{
                'scale-50 bg-white/20': !isActive && !isFeatured,
                'h-1 w-1 ': isActive || isFeatured,
                'bg-white/50': isActive && !isFeatured,
                'bg-electric-violet-200': isFeatured,
              }"
            :style="{ '--duration': `${(index % 3) * 1.5}s` }"
            class="relative h-1 w-1 rounded-full transition-all duration-500 [animation-duration:--duration]"></div>
      </StarGridItem>
    </StarGrid>

    <svg class="absolute -right-[50%] z-[-10] bottom-0 top-0 m-auto h-[650px] opacity-60"
         viewBox="0 0 449 425.2" xmlns="http://www.w3.org/2000/svg">
      <path
          d="m385.8 261c-9.8-23.9-8-50.7-10.5-76.4-3.3-34.6-15-69-36.8-96.1s-54.5-46.1-89.2-48c-25.3-1.3-52.3 7.5-67.6 27.7-16.3 21.7-16.9 52.7-34.7 73.3-12.9 15-32.8 22-52.2 25.9s-39.5 5.6-57.9 12.9c-10.7 4.3-21 10.7-27.2 20.4-9.7 15.6-7 36.4 2.4 52s24.7 26.7 40.1 36.3c15.5 9.6 31.8 18.1 45.4 30.3 21.9 19.6 35.2 47.1 56.6 67.3 32.3 30.4 80 40.3 124 35s85.2-24.3 124.3-45.3c10.3-5.6 20.7-11.4 29-19.7 6-6 14.3-16.9 13.8-26-.5-9.4-12.7-14.8-20-20.8-16.2-13.4-31.4-28.9-39.5-48.6z"
          fill="#6C4FEB"/>
    </svg>

    <!--    <img alt="An illustration of an open cardboard box"-->
    <!--         class="samos-drop-shadow aspect-square w-full object-contain mix-blend-luminosity absolute -right-[50%] z-[-10] bottom-0 top-0 m-auto opacity-20"-->
    <!--         src="../../img/how-it-works/box-6.svg"-->
    <!--         style="scale: 0.8;">-->

    <h2 class="text-xl font-medium tracking-tight sm:text-3xl mb-6 text-center">Get a quote instantly</h2>
    <p v-if="shipping_country.country_name" class="mb-3">I’m sending a parcel from UK to <span
        class="font-semibold underline underline-offset-2">{{ shipping_country.country_name }}</span></p>
    <p v-else class="mb-3">I’m sending a parcel from UK to...</p>

    <div class="grid sm:gap-8 lg:gap-0 grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 max-w-lg">
      <div class="max-w-xs">
        <Combobox v-model="shipping_country">

          <ComboboxLabel class="block text-sm font-medium leading-6 text-white">Country</ComboboxLabel>

          <div class="relative mt-0.5">
            <div
                class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-white sm:text-sm"
            >
              <ComboboxInput
                  :displayValue="(country) => country.country_name"
                  class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                  placeholder="Select a country"
                  @change="query = $event.target.value"
                  @click="onSearchInputFocus(true)"
              />
              <ComboboxButton
                  ref="comboBtn" class="absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <ChevronUpDownIcon
                    aria-hidden="true"
                    class="h-5 w-5 text-gray-400"
                />
              </ComboboxButton>
            </div>
            <TransitionRoot
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                @after-leave="query = ''"
            >
              <ComboboxOptions
                  class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-10"
              >
                <div
                    v-if="filtered_countries.length === 0 && query !== ''"
                    class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  No country matching "{{ query }}" found.
                </div>

                <ComboboxOption
                    v-for="country in filtered_countries"
                    :key="country.code"
                    v-slot="{ selected, active }"
                    :value="country"
                    as="template"
                >
                  <li
                      :class="{
                  'bg-electric-violet-600 text-white': active,
                  'text-gray-900': !active,
                }"
                      class="relative cursor-pointer select-none py-2 pl-10 pr-4"
                  >
                <span
                    :class="{ 'font-medium': selected, 'font-normal': !selected }"
                    class="block truncate"
                >
                  {{ country.country_name }}
                </span>
                    <span
                        v-if="selected"
                        :class="{ 'text-white': active, 'text-electric-violet-600': !active }"
                        class="absolute inset-y-0 left-0 flex items-center pl-3"
                    >
                  <CheckIcon aria-hidden="true" class="h-5 w-5"/>
                </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </TransitionRoot>
          </div>
        </Combobox>
      </div>

      <div class="mt-3 sm:mt-0 lg:mt-3 max-w-xs">
        <label class="block text-sm font-medium leading-6 text-white" for="weight">Weight</label>
        <div class="relative mt-0.5 rounded-md shadow-sm">
          <input id="weight" v-model="parcel_weight" aria-describedby="weight-unit"
                 class="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 shadow-md ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white focus-visible:outline-white sm:text-sm sm:leading-6"
                 max="20"
                 min="0.1" name="weight" placeholder="2.4" step="0.1" type="number"/>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span id="weight-unit" class="text-gray-500 sm:text-sm">kg</span>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <p class="text-sm">Quote</p>
      <p v-if="calculated_price === 'unavailable'" class="text-xl font-semibold tracking-wider">Unavailable</p>
      <p v-else class="text-xl font-semibold tracking-wider">£{{ calculated_price }}</p>
    </div>

    <div class="mt-4">
      <p class="text-sm">Maximum weight: 20 kg</p>
      <p class="text-sm">Maximum dimension: 100 cm × 43 cm × 43 cm</p>
    </div>

    <div class="mt-4">
      <div class="mt-10 flex flex-col-reverse gap-y-4 items-center justify-end gap-x-4">
        <Link class="text-sm font-semibold leading-6 text-white hover:underline focus-visible:outline-white"
              href="/pricing/samos-shipping-rates">View all our rates
        </Link>
        <Link
            class="rounded-full bg-white border border-electric-violet-500 px-12 py-2.5 text-sm font-semibold tracking-wider uppercase text-electric-violet-600 shadow-sm hover:bg-electric-violet-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            href="/start-shipping" @click.prevent="startShipping()">Start shipping today
        </Link>
      </div>
    </div>

  </div>
</template>
<script setup>
import {computed, onMounted, reactive, ref, watch} from 'vue';
import {Link} from '@inertiajs/vue3';
import StarGrid from "@/Components/StarGrid.vue";
import StarGridItem from "@/Components/StarGridItem.vue";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
  TransitionRoot,
} from '@headlessui/vue';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/vue/20/solid';
import axios from 'axios';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


let shipping_country = ref(null);
shipping_country.value = emptyCountry();
let countries = ref([]);
const is_loading = ref(true);
const parcel_weight = ref('1.0');
const errors = reactive({});
const query = ref('');
const comboBtn = ref(null);
const hasInteracted = ref(false);
const currentCountryIndex = ref(0);
const items = Array(40).fill(0)
const isServer = typeof document === 'undefined';

onMounted(() => {
  axios.post('/api/price-calculator', {})
      .then((response) => {
        countries.value = response.data;
        is_loading.value = false;
        if (props.preselect) {
          countries.value.forEach(country => {
            if (country.country_name === props.preselect) {
              selectCountry(country);
            }
          });
        }
      }, (error) => {
        is_loading.value = false;
        console.error(error);
      });
})

const startShipping = () => {

  gtag('event', 'cta_clicked', {
    'event_category': 'cta',
    'event_label': 'start_shipping',
  });

  if (!isServer) {
    if (hasInteracted.value) {
      window.location.href = '/start-shipping?country=' + shipping_country.value.country_name;
      return;
    }
    window.location.href = '/start-shipping';
  }

}

const isInteracting = () => {
  hasInteracted.value = true;
}

const props = defineProps({
  preselect: String,
});

function emptyCountry() {
  return {
    id: null,
    country_name: "",
    is_eu: true,
    country_code: "",
    price_1: 0,
    price_5: 0,
    price_10: 0,
    price_15: 0,
    price_20: 0,
  }
}

function switchToNextCountry() {
  if (countries.value.length === 0) {
    return;
  }
  if (currentCountryIndex.value === countries.value.length - 1) {
    currentCountryIndex.value = 0;
  } else {
    currentCountryIndex.value++;
  }

  selectCountry(countries.value[currentCountryIndex.value]);

}


const onSearchInputFocus = (delay = false) => {
  if (!delay) {
    comboBtn.value.el.click();
  } else {
    setTimeout(() => {
      comboBtn.value.el.click();
    }, 1);
  }
}

watch(parcel_weight, async (value) => {
  delete errors['parcel_weight'];

  if (value > 30 || value < 0.1) {
    errors['parcel_weight'] = 'The weight can be between 0.1 and 30 kg.';
  }
});

const filtered_countries = computed(() =>
    query.value === ''
        ? countries.value
        : countries.value.filter((country) => {
          return country.country_name.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, ''));
        })
)

const weight = computed(() => {
  return parcel_weight.value;
});

const calculated_price = computed(() => {
  return quote();
});

function quote() {
  if (shipping_country.value === null) {
    return '0.00';
  }

  if (shipping_country.value.is_eu) {
    return euQuote();
  } else {
    return nonEuQuote();
  }

}

function euQuote() {
  let price_type = 'price_1';

  switch (true) {
    case weight.value <= 1:
      price_type = 'price_1';
      break;
    case weight.value > 1 && weight.value <= 5:
      price_type = 'price_5';
      break;
    case weight.value > 5 && weight.value <= 10:
      price_type = 'price_10';
      break;
    case weight.value > 10 && weight.value <= 15:
      price_type = 'price_15';
      break;
    case weight.value > 15 && weight.value <= 20:
      price_type = 'price_20';
      break;
    default:
      return 'unavailable';
  }

  let quote = parseFloat(shipping_country.value[price_type] / 100).toFixed(2) || '0.00';

  if (hasInteracted.value) {
    gtag('event', 'shipping_quote', {
      'event_category': 'shipping',
      'event_label': shipping_country.value.country_name,
      'value': quote
    });
  }

  return quote;

}

function nonEuQuote() {
  let price_type = 'price_0-5';

  switch (true) {
    case weight.value <= 0.5:
      price_type = 'price_0-5';
      break;
    case weight.value > 0.5 && weight.value <= 1:
      price_type = 'price_1-0';
      break;
    case weight.value > 1 && weight.value <= 1.5:
      price_type = 'price_1-5';
      break;
    case weight.value > 1.5 && weight.value <= 2:
      price_type = 'price_2-0';
      break;
    case weight.value > 2 && weight.value <= 2.5:
      price_type = 'price_2-5';
      break;
    case weight.value > 2.5 && weight.value <= 3:
      price_type = 'price_3-0';
      break;
    case weight.value > 3 && weight.value <= 3.5:
      price_type = 'price_3-5';
      break;
    case weight.value > 3.5 && weight.value <= 4:
      price_type = 'price_4-0';
      break;
    case weight.value > 4 && weight.value <= 4.5:
      price_type = 'price_4-5';
      break;
    case weight.value > 4.5 && weight.value <= 5:
      price_type = 'price_5';
      break;
    case weight.value > 5 && weight.value <= 6:
      price_type = 'price_6';
      break;
    case weight.value > 6 && weight.value <= 7:
      price_type = 'price_7';
      break;
    case weight.value > 7 && weight.value <= 8:
      price_type = 'price_8';
      break;
    case weight.value > 8 && weight.value <= 9:
      price_type = 'price_9';
      break;
    case weight.value > 9 && weight.value <= 10:
      price_type = 'price_10';
      break;
    case weight.value > 10 && weight.value <= 15:
      price_type = 'price_15';
      break;
    case weight.value > 15 && weight.value <= 20:
      price_type = 'price_20';
      break;
    default:
      return 'unavailable';
  }

  let quote = parseFloat(shipping_country.value[price_type] / 100).toFixed(2) || '0.00';

  if (hasInteracted.value) {
    gtag('event', 'shipping_quote', {
      'event_category': 'shipping',
      'event_label': shipping_country.value.country_name,
      'value': quote
    });
  }

  return quote;
}

function selectCountry(country) {
  if (country.id == null) {
    return;
  }
  shipping_country.value = country;
}
</script>