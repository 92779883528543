<script setup>
import {computed, inject, provide, ref} from "vue"

defineProps({
  as: {
    type: String,
    default: "div",
  },
})

const container = ref()

const context = inject("stars-context")

const index = computed(() => {
  return context.peers?.value ? context.peers.value.indexOf(container.value) : -1
})

const isActive = computed(() => context.activeItems.includes(index.value))
const isFeatured = computed(() => context.featuredItem.value === index.value)

provide("isActive", isActive)
</script>

<template>
  <component :is="as" ref="container">
    <slot :is-active="isActive" :is-featured="isFeatured"/>
  </component>
</template>
